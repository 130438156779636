<template>
  <div class="lvdetails">
    <div class="topbg"></div>
    <div class="lsinfo base_w">
      <div class="infoleft">
        <img :src="lawyerObj.photo" alt="" class="lsimg" />
        <div class="infodet">
          <p class="text1">
            {{ lawyerObj.lawyerName }}律师 <span>{{ lawyerObj.addr }}</span>
          </p>
          <p class="text2">
            好评率：<span>{{ lawyerObj.haopinhlv }}% </span> 接单量:
            <span>{{ lawyerObj.jiedanums }}次</span>
          </p>
          <div class="text3">
            <p>擅长领域：</p>
            <span v-for="(data, index) in lawyerObj.goodsIdsArr" :key="index">{{
              data
            }}</span>
          </div>
        </div>
      </div>
      <div class="inforight">
        <div class="right1" @click.stop="seitchIslike">
          <!-- 是否关注 0未关注  1已关注 -->
          <p class="flex_center" v-if="lawyerObj.islike == 1">
            <img src="@/assets/img/guan.png" alt="" class="mr_10"/> <span> 取消关注 </span>
          </p>
          <p class="flex_center" v-if="lawyerObj.islike == 0">
            <img src="@/assets/img/guanzhu_wei.png" alt="" class="mr_10"/>
            <span> 关注 </span>
          </p>
        </div>

        <div class="imgbox1 flexbox" v-if="lawyerObj.peopleTouxiangArr">
          <img
            :src="data"
            alt=""
            v-for="(data, index) in lawyerObj.peopleTouxiangArr"
            :key="index"
            :style="{
              zIndex: 1000 - inedx,
              position: 'absolute',
              left: index * 10 - 18 + 'px'
            }"
          />
          <p
            class="guanz1"
            :style="{
              paddingLeft: lawyerObj.peopleTouxiangArr.length * 10 + 'px'
            }"
          >
            {{ lawyerObj.peoplelikeNum }}人关注了律师
          </p>
        </div>
        <div class="imgbox1 flexbox" v-else>
          <p class="guanz1">{{ lawyerObj.peoplelikeNum }}人关注了律师</p>
        </div>

        <div
          class="btnbox"
          @click.stop="tourl('/wenls?lawid=' + lawyerObj.id)"
        >
          向他提问
        </div>
      </div>
    </div>

    <p class="kong"></p>

    <div class="mainbox base_w flex">
      <div class="common_index_leftwidth">
        <div class="common_title_box">
          <span class="title">全部回答</span>
        </div>
        <wenda
          ref="newwenda"
          :type="3"
          :wendalistArr="wentypedalist"
        ></wenda>

        <div class="paginationblock">
          <el-pagination
            :hide-on-single-page="true"
            @current-change="handleCurrentChange"
            :current-page.sync="pagination.currentPage"
            :page-size="pagination.size"
            layout="total, prev, pager, next"
            :total="pagination.total">
          </el-pagination>
        </div>

        <NoData v-if="wentypedalist.length <= 0"></NoData>

      </div>
      <div class="common_index_rightwidth">
        <div class="common_title_box">
          <span class="title">提供服务</span>
        </div>
        <div class="fuwuspan flexbox">
          <span v-for="(item,i) in lawyerObj.fuwjiage" :key="i" @click.stop="tourlwenls(item)">{{item.name}}</span>
        </div>

        <div class="lsdangan">
          <p class="dangan1"><span> </span> 律师档案</p>
          <p class="dangan2">执业地区：{{ lawyerObj.addr }}</p>
          <p class="dangan2">执业时间：{{ lawyerObj.workYears }}年</p>
          <p class="dangan2">执业证号：{{ lawyerObj.cardNo }}</p>
          <p class="dangan2">擅长领域：{{ lawyerObj.goodsIds }}</p>
        </div>

        <div class="lsdangan">
          <p class="dangan1"><span> </span> 律师简介</p>
          <div class="dangan2">
            {{ lawyerObj.details }}
          </div>
        </div>

        <div class="flzxbox">
          <div class="common_title_box">
            <span class="title">法律资讯</span>
          </div>
          <div class="newsbox">
            <news :type="2" :list="informationlist"></news>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wenda from '@/components/wenda'
import news from '@/components/news'
import NoData  from '@/components/NoData'

import apiUrl from '@/api/main'
export default {
  components: { wenda, news, NoData },
  data() {
    return {
      lawyerObj: {}, // 律师详情
      informationlist: [], //法律资讯
      wentypedalist: [], // 问答列表

      // 分页p
      pagination:{
        size: 5, // 每页几条
        currentPage: 1,
        total: 0
      }
    }
  },
  computed: {
    // etIconStyle(num) {
    //  return `transform: translateX(${num}* 10px);`
    // }
  },
  watch: {},
  created() {},
  mounted() {
    // 律师详情
    this.getdetail();
    this.getinformationlist() // 法律资讯
    this.gettypewendalist() // 问答
    // 跳转回顶部
    document.documentElement.scrollTop = 0;
  },
  methods: {
    // 跳转
    tourl(url) {
      this.$router.push({
        path: url
      })
    },
    tourlwenls(item) {
      let form = {
        type:item.name,
        price:item.price,
        id:item.id,
        drawableid:item.drawableid
      }
      localStorage.setItem('askinfolv', JSON.stringify(form));

      if(item.name == '文书代写'){
        this.$router.push({
          path: '/falvwenshu?fuwutype=' + item.name + '&lawid=' + this.lawyerObj.id + '&golvback=true'
        })
      } else {
        this.$router.push({
          path: '/wenls?fuwutype=' + item.name + '&lawid=' + this.lawyerObj.id + '&golvback=true'
        })
      }
     
    },
    // id  获取律师详情
    getdetail() {
      let data = {
        lawyerId: this.$route.query.id,
        userId:localStorage.getItem('userId')
      }
      apiUrl.lsLawyerinfo(data).then((res) => {
        // 擅长领域
        if (res.data.goodsIds) {
          res.data.goodsIdsArr = res.data.goodsIds.split(',')
        }
        // 粉丝头像
        if (res.data.peopleTouxiang) {
          res.data.peopleTouxiangArr = res.data.peopleTouxiang.split(',')
        }

        // 执业证号
        if (res.data.cardNo) {
          res.data.cardNo =
            res.data.cardNo.slice(0, 5) + '******' + res.data.cardNo.slice(-6)
        }

        // 提供服务
        res.data.fuwjiage = res.data.fuwjiage ? JSON.parse(res.data.fuwjiage) : [];
      

        this.lawyerObj = res.data;
        console.log(this.lawyerObj )
      })
    },
    // 关注取消关注律师
    seitchIslike() {
      let data = {
        lawyerId: this.$route.query.id,
        userId: localStorage.getItem('userId')
      }
      apiUrl.lsLawyerlike(data).then((res) => {
        if (res.code == 0) {
          this.lawyerObj.islike = this.lawyerObj.islike == 0 ? 1 : 0;
          this.getdetail();
        }
      })
    },
    // 获取法律资讯
    getinformationlist() {
      let data = {
        userId: '',
        pageSize: 3,
        pageNum: 1
      }
      apiUrl.lsInformationlist(data).then((res) => {
        this.informationlist = res.rows
      })
    },

    // 获取问答列表
    gettypewendalist() {
      let data = {
        lawyerId:this.$route.query.id,
        pageSize: this.pagination.size,
        pageNum: this.pagination.currentPage
      }
      apiUrl.wendalist(data).then((res) => {
        this.wentypedalist = res.rows;

        this.pagination.total = res.total;
      })
    },
    // 分页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);

      this.pagination.currentPage = val
      this.gettypewendalist();
    }

    
  }
}
</script>
<style lang='scss' scoped>
.lvdetails {
  .topbg {
    height: 200px;
    background: #131627;
  }
  .mr_10{
    margin-right: 10px;
  }

  .lsinfo {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    // transform: translateY(-114px);
    margin-top: -114px;
    padding-bottom: 32px;
    .infoleft {
      display: flex;
      // align-items: flex-end;
      align-items: center;

      .lsimg {
        width: 200px;
        height: 265px;
        border-radius: 16px;
        margin-right: 24px;
      }
      .infodet {
        min-height: 90px;
        padding-top: 130px;
        width: 1000px;
        .text1 {
          font-size: 26px;
          font-weight: 600;
          color: #333333;
          margin-right: 16px;
          span {
            font-size: 16px;
            font-weight: 400;
            color: #333333;
          }
        }
        .text2 {
          font-size: 17px;
          color: #333333;
          margin-top: 13px;
          span {
            color: #305bfe;
            margin-right: 16px;
          }
        }
        .text3 {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          color: #333333;
          font-size: 16px;
          margin-top: 34px;
          width: 550px;
          p {
            margin-bottom: 10px;
          }
          span {
            border-radius: 13px;
            border: 1px solid #cccccc;
            padding: 4px 14px;
            margin-left: 12px;
            margin-bottom: 12px;
          }
        }
      }
    }
    .inforight {
      text-align: right;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .right1 {
        background: rgba(48, 91, 254, 0.07);
        border-radius: 17px;
        line-height: 30px;
        text-align: center;
        font-size: 13px;
        color: #305bfe;
        padding: 2px 15px;
        cursor: pointer;
        img {
          width: 20px;
          height: 20px;
          // margin-right: 7px;
          // transform:translateX(50px);
        }
      }

      .imgbox1 {
        margin-top: 16px;
        height: 27px;
        position: relative;
        img {
          width: 27px;
          height: 27px;
          border-radius: 50%;
          // margin-right: -10px !important;
        }
        .guanz1 {
          display: inline-block;
          height: 27px;
          line-height: 27px;
          font-size: 13px;
          color: #60666f;
        }
      }

      .btnbox {
        width: 184px;
        height: 48px;
        line-height: 48px;
        border-radius: 36px;
        margin-top: 21px;
        font-size: 18px;
      }
    }
  }

  .kong {
    height: 7px;
    background: #f5f5f5;
  }
  .mainbox {
    margin-top: 33px;
    padding-bottom: 67px;
    .fuwuspan {
      flex-wrap: wrap;
      span {
        border-radius: 25px;
        border: 1px solid #979797;
        padding: 9px 34px;
        margin-right: 19px;
        margin-top: 24px;
        cursor: pointer;
      }
      span:hover{
        border: 1px solid #305bfe;
        color: #305bfe;
      }
    }

    .lsdangan {
      // width: 488px;
      width: 586px;
      min-height: 247px;
      background: #fafbfd;
      border-radius: 10px;
      border: 1px solid #eeeeee;
      padding: 20px 27px;
      margin-top: 33px;
      .dangan1 {
        font-size: 26px;
        font-weight: 500;
        color: #333333;
        span {
          display: inline-block;
          width: 6px;
          height: 18px;
          background: #305bfe;
          border-radius: 2px;
          margin-right: 13px;
          font-size: 18px;
        }
      }
      .dangan2 {
        font-size: 18px;
        color: #333333;
        margin-top: 24px;
        padding-left: 25px;
        line-height: 30px;
      }
    }

    .flzxbox {
      margin-top: 33px;
      .newsbox {
        margin-top: 13px;
        // width: 488px;
        width: 586px;
        background: #f8f8f8;
        border-radius: 10px;
        padding: 27px;
      }
    }
  }
}
</style>